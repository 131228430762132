import { Component, OnInit } from '@angular/core';
declare var gigya:any
declare var profile:any
declare var logout:any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit() {
      /**
       * 
       * HEAD 
       * 
       */
      let currentURL = window.location.href;
      function getAPIKey(url) {
        let queryStart = url.indexOf("=")+1;
        let queryEnd = url.indexOf("&");
        let urlQuery = url.slice(queryStart, queryEnd);
        return urlQuery;
      }

      let apiKey = getAPIKey(currentURL);
      localStorage.setItem('API_KEY', apiKey);

      function hasKey(){
        if (localStorage.getItem('API_KEY').startsWith('http')) {
          document.getElementById('error').innerHTML = "Error loading page, please contact site administrator";
          console.log('API key is not found');
          return false;
        }
        return true;
      }

      function loadGigya(callback) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://cdns.gigya.com/js/gigya.js?apiKey=${apiKey}`;
        s.text = '{siteName: "cxpoc.com"}';
        if (hasKey()) {
          s.addEventListener('load', callback);
        }
        document.getElementsByTagName('head')[0].appendChild(s);
      }
      
      /**
       * 
       * BODY
       * 
       */
      function GigyaLoadFunctions() {
        gigya.accounts.addEventHandlers({ onLogin: loginHandler });
        gigya.accounts.getAccountInfo({ callback: checkLogin });
      }

      function loginHandler(res) {
        console.log("page JS handler: you logged in with UID: " + res.UID);
        console.log(res);
        checkLogin(res);
      }

      function checkLogin(res) {
        var login = document.getElementById("login");
        gigya.accounts.showScreenSet({
          containerID: "login",
          screenSet: "SFDC-eCom-RegistrationLogin",
          startScreen: "gigya-reset-password-screen",
        });
        login.style.display = "block";
        logout.style.display = "none";
        profile.style.display = "none";
      }

      loadGigya(GigyaLoadFunctions.bind(this));
  }
}
